<template>

	<aside class="main-sidebar sidebar-dark-primary elevation-4">
		<a href="/" class="brand-link">
			<span class="brand-text font-weight-light">
				PRIU Lab
			</span>
		</a>

		<div class="sidebar">
		</div>
		
		<el-scrollbar style="height: 87vh;">
			<el-menu :default-openeds="['1', '2']">
				
				<el-submenu index="1">
					<template slot="title"><i class="el-icon-menu"></i>文字技术</template>

					<!-- 1、特色功能 -->
					<el-menu-item-group>
						<template slot="title">特色功能</template>
						<!-- a.通用文字识别(SceneTextRecognition) -->
						<router-link to="str" class="nav-link">
							<el-menu-item index="1-1">通用文字识别</el-menu-item>
						</router-link>
						<!-- b.表格重建(Form) -->
						<router-link to="form" class="nav-link">
							<el-menu-item index="1-2">表格重建</el-menu-item>
						</router-link>
						<!-- c.核酸检测结果提取(KIE) -->
						<router-link to="kie" class="nav-link">
							<el-menu-item index="1-3">核酸检测结果提取</el-menu-item>
						</router-link>
						<!-- d.身份证识别(IdCard) -->
						<router-link to="idcard" class="nav-link">
							<el-menu-item index="1-4">身份证识别</el-menu-item>
						</router-link>
						<!-- e.不动产合同检验(Easte) -->
						<router-link to="easte" class="nav-link">
							<el-menu-item index="1-5">不动产合同检验</el-menu-item>
						</router-link>
						<!-- f.营业执照识别(BusinessLicense) -->
						<router-link to="businesslicense" class="nav-link">
							<el-menu-item index="1-6">营业执照识别</el-menu-item>
						</router-link>
						<!-- g.联机手写文本行识别(HWCharRecognition) -->
						<router-link to="hwcr" class="nav-link">
							<el-menu-item index="1-7">联机手写文本行识别</el-menu-item>
						</router-link>
					</el-menu-item-group>

					
					<!-- 2、通用 -->
					<el-menu-item-group>
						<template slot="title">通用</template>
						<!-- a.网络图片文字识别（WebImageLoc） -->
						<router-link to="webimageLoc" class="nav-link">
							<el-menu-item index="1-8">网络图片文字识别</el-menu-item>
						</router-link>
						<!-- b.手写文字识别(HandWriting) -->
						<router-link to="handwriting" class="nav-link">
							<el-menu-item index="1-9">手写文字识别</el-menu-item>
						</router-link>
						<!-- c.数字识别（Number） -->
						<router-link to="numbers" class="nav-link">
							<el-menu-item index="1-10">数字识别</el-menu-item>
						</router-link>
						<!-- d.办公文档识别（DocAnalysisOffice） -->
						<router-link to="docanalysisoffice" class="nav-link">
							<el-menu-item index="1-11">办公文档识别</el-menu-item>
						</router-link>
						<!-- e.印章识别（Seal） -->
						<router-link to="seal" class="nav-link">
							<el-menu-item index="1-12">印章识别</el-menu-item>
						</router-link>
					</el-menu-item-group>

					<!-- 3、卡证 -->
					<el-menu-item-group>
						<template slot="title">卡证</template>
						<!-- a.护照识别 （passport）-->
						<router-link to="passport" class="nav-link">
							<el-menu-item index="1-13">护照识别</el-menu-item>
						</router-link>
					</el-menu-item-group>
						
					<!-- 4、交通 -->
					<el-menu-item-group>
						<template slot="title">交通</template>
						<!-- a.车牌识别（LicensePlate） -->
						<router-link to="licensePlate" class="nav-link">
							<el-menu-item index="1-14">车牌识别</el-menu-item>
						</router-link>
						<!-- b.VIN码识别（VinCode） -->
						<router-link to="vinCode" class="nav-link">
							<el-menu-item index="1-15">VIN码识别</el-menu-item>
						</router-link>
						<!-- c.车牌证件照混贴识别（MixedMultiVehicle） -->
						<router-link to="mixedmultivehicle" class="nav-link">
							<el-menu-item index="1-16">车牌证件照混贴识别</el-menu-item>
						</router-link>
					</el-menu-item-group>
						
					<!-- 5、财务 -->
					<el-menu-item-group>
						<template slot="title">财务</template>
						<!-- a.通用票据识别（Receipt） -->
						<router-link to="receipt" class="nav-link">
							<el-menu-item index="1-17">通用票据识别</el-menu-item>
						</router-link>
					</el-menu-item-group>
						
					<!-- 6、教育 -->
					<el-menu-item-group>
						<template slot="title">教育</template>
						<!-- a.试卷分析与识别（DocAnalysis） -->
						<router-link to="docAnalysis" class="nav-link">
							<el-menu-item index="1-18">试卷分析与识别</el-menu-item>
						</router-link>
						<!-- b.公式识别（Formula） -->
						<router-link to="formula" class="nav-link">
							<el-menu-item index="1-19">公式识别</el-menu-item>
						</router-link>
					</el-menu-item-group>
						

					<!-- 7、其他 -->
						<!-- 6、其他 -->
						<el-menu-item-group>
							<template slot="title">其他</template>
							<!-- a.仪器仪表盘读数识别（Meter） -->
							<router-link to="meter" class="nav-link">
								<el-menu-item index="1-20">仪器仪表盘读数识别</el-menu-item>
							</router-link>
						</el-menu-item-group>
						
				</el-submenu>


				<el-submenu index="2">
					<template slot="title"><i class="el-icon-setting"></i>数据标注</template>
					<el-menu-item-group>
						<template slot="title">数据标注</template>
						<router-link to="annotation" class="nav-link">
							<el-menu-item index="2-1">数据标注</el-menu-item>
						</router-link>
					</el-menu-item-group>
				</el-submenu>

			</el-menu>
		</el-scrollbar>
	</aside>
	
</template>

<script>
export default {
	name: 'Sidebar',

	data () {
		return {
			msg: 'Welcome to Sidebar',
		}
	},

	methods: {
	},


}
</script>

<style scoped>


</style>


