<template>
  <div class="hello">
  
	<footer class="main-footer">
		<strong>Copyright &copy; 2022 </strong>
			福建省模式识别与图像理解重点实验室
    All rights reserved. <a href="https://beian.miit.gov.cn/" target="_blank">闽ICP备2022011391号-1</a>
		<div class="float-right d-none d-sm-inline-block">
			<b>Version</b> 0.1.1
		</div>
	</footer>

  </div>

</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
      msg: 'Welcome to Footer'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
