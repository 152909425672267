<template>
	<nav class="main-header navbar navbar-expand navbar-white navbar-light">
		<router-link to="/">
			<img class="animation__shake" src="@/resource/img/Logo.png" alt="Logo" height="60" width="60">
		</router-link>

		<!-- Left navbar links -->
		<ul class="navbar-nav">
			<li class="nav-item">
				<a class="nav-link" data-widget="pushmenu" href="#"><i class="fa fa-bars"></i></a>
			</li>
			<li class="nav-item d-none d-sm-inline-block">
				<router-link to="/" class="nav-link">
					福建省模式识别与图像理解重点实验室
				</router-link>
			</li>
		</ul>

	</nav>
</template>

<script>

export default {
	name: 'Header',

	data () {
		return {
			msg: 'Welcome to Header',
		}
	},

}
</script>

<style scoped>

</style>
