<template>
	<div class="wrapper" >

		<Sidebar style="position:fixed;" ></Sidebar>

		<Header></Header>
		<div class="content-wrapper">
			<div class="card"> 
				<router-view />
			</div>
		</div>

		<Footer></Footer>

	</div>
</template>

<script>

//import $ from 'jquery'
import Footer from '@/components/common/Footer'
import Header from '@/components/common/Header'
import Sidebar from '@/components/common/Sidebar'

export default {
	name: 'Home',
	data () {
		return {
			msg: 'Welcome to Home',
		}
	},

	components: {
		Footer,
		Header,
		Sidebar,
	},

	methods: {

	},

	mounted() {
	},


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	
</style>

